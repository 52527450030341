<template>
  <div class="wrap">
    <div class="top-banner">
      <div class="w-1200">
        <div class="title">指标/变量加工平台</div>
        <div class="en">DU-IPP</div>
        <p>主要应用场景为实时指标计算领域，替代现有Java/Pathon硬编码，</p>
        <p>提供便捷的指标开发、测试、管理一站式系统</p>
      </div>
    </div>
    <div class="main-box w-1200">
      <div class="main-item">
        <div class="title">功能介绍</div>
        <div class="content">
          <div class="function-box">
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-1.png"/>
              <label>数据源管理</label>
              <p>对接各类数据库，对接各类API接口。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-2.png"/>
              <label>报文解析</label>
              <p>对接口返回的半结构数据结构化解析存储。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-3.png"/>
              <label>模型管理</label>
              <p>对接口返回的报文进行建模，字段映射落标，标准化存储。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-4.png"/>
              <label>数据标准管理</label>
              <p>对接口输入输出字段，节点名称等进行标准化管理（包含 字段名字、字段类型、字段描述、枚举值、阈值范围等）。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-5.png"/>
              <label>数据质量管理</label>
              <p>校验接口输入输出字段类型、枚举值、阈值范围、是否必须等是否符合接口规范，校验外部接口返回是否符合标准，并实时预警、定期出具数据质量分析报告</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-6.png"/>
              <label>UDF函数管理</label>
              <p>用户可根据需要使用Java/Python/SQL等多种方式自定义指标加工函数，在计算基础指标的时候通过传入不同参数获取多组指标。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-7.png"/>
              <label>数据标准加工</label>
              <p>基于原始的接口解析数据模型，映射到标准模型，在该阶段，可以做代码转换，字段映射等操作。</p>
            </div>
						<div class="function-item">
							<img src="~@/assets/images/product/function-icon-8.png"/>
							<label>基础指标管理</label>
							<p>用户可使用SQL方式基于接口解析的原始数据模型，标准数据模型，共性数据模型，来做复杂的统计，并以此计算基础指标；也可基于UDF函数，通过传递不同参数来计算基础指标。</p>
						</div>
						<div class="function-item">
							<img src="~@/assets/images/product/function-icon-8.png"/>
							<label>衍生指标管理</label>
							<p>基于基础指标，通过单个指标或多个指标综合规则，来出具新的衍生指标。</p>
						</div>
						<div class="function-item">
							<img src="~@/assets/images/product/function-icon-8.png"/>
							<label>服务管理</label>
							<p>在指标池筛选多个指标，组成一个服务，暴露给下游调用，同时系统自动根据筛选的指标范围，生成接口文档，并提供测试示例与返回报文示例。</p>
						</div>
						<div class="function-item">
								<img src="~@/assets/images/product/function-icon-8.png"/>
							<label>版本管理</label>
							<p>系统对各类代码进行了版本的记录与保存，在编辑界面，审核界面等地方提供了版本高亮对比等功能。</p>
						</div>
						<div class="function-item">
							<img src="~@/assets/images/product/function-icon-8.png"/>
							<label>审核机制</label>
							<p>系统可开启审核机制，包含变更前审核，获取编辑权限；变更后审核，审核通过后相关指标立即生效。</p>
						</div>
          </div>
        </div>
      </div>
      <div class="main-item">
        <div class="title">产品架构</div>
        <div class="content">
          <img src="~@/assets/images/ipp-architecture.png"/>
        </div>
      </div>
      <div class="main-item">
        <div class="title">核心优势</div>
        <div class="content">
          <div class="core-box">
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-1.png"/>
              <div class="text-box">
                <label>高度化配置</label>
                <p>从数据源的接入，数据解析存储，到UDF函数，再到基础指标、衍生指标，服务接口等均可在线配置；接口文档实时更新</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-2.png"/>
              <div class="text-box">
                <label>服务发布</label>
                <p>对外以服务维度发布一组指标，系统自动根据这组指标所需数据源入参信息，形成接口文档，并在服务调用时，调用相应数据源来计算指标</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-3.png"/>
              <div class="text-box">
                <label>DevOps结合体</label>
                <p>系统可在线查询交易记录，查询每笔交易查询的数据源情况、指标计算耗时情况、返回值等信息，辅助便捷运营</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-4.png"/>
              <div class="text-box">
                <label>高效计算</label>
                <p>依托内存计算引擎，指标计算在毫秒级完成</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="main-item">
        <div class="title">应用场景</div>
        <div class="content">
          <div class="scenario-box">
            <div class="scenario-item">
              <label>数据分析，面向企业精细化运营</label>
              <p>汇聚处理各类业务数据，基于中台强大的引擎能力完成复杂的分析与监控工作，灵活响应并满足新需求，实现对用户千人千面的精细化运营</p>
              <img src="~@/assets/images/rdc/scenario-1.png"/>
            </div>
            <div class="scenario-item">
              <label>数据治理，为企业数据保驾护航</label>
              <p>针对各种来源的数据，统一数据标准、统一数据资产，同时保障数据安全，从组织和个人角度，促进数据在组织内无障碍地进行交流</p>
              <img src="~@/assets/images/rdc/scenario-2.png"/>
            </div>
            <div class="scenario-item">
              <label>数据应用，助力企业数字化转型</label>
              <p>分析、管理数据，基于硬件和业务数据，完成企业的事前风险预测、事中监控调度和事后问题排查工作，保障生产和供应的可靠性</p>
              <img src="~@/assets/images/rdc/scenario-1.png"/>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
.wrap {
  background-color: #F7F8FA;
}
.top-banner {
  height: 320px;
  background-image: url('~@/assets/images/product/banner.png');
  background-repeat: no-repeat;
  background-position: center;
  .title {
    font-size: 40px;
    font-weight: 100;
    color: #FFFFFF;
    margin-bottom: 20px;
    padding-top: 82px;
  }
  .en {
    font-size: 40px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  p {
    font-size: 18px;
    font-weight: 100;
    color: #FFFFFF;
    opacity: 0.8;
    margin-bottom: 14px;
  }
}
.main-box {
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #4F5969;
    padding: 40px 0;
  }
  .content {
    img {
      width: 1199px;
    }
  }
  .function-box {
    overflow: hidden;
    .function-item {
      box-sizing: border-box;
      float: left;
      width: 285px;
      height: 244px;
      background: #FFFFFF;
      border-radius: 5px;
      padding: 30px;
      margin-right: 19px;
      >img {
        width: 26px;
      }
      &:nth-child(-n+8) {
        margin-bottom: 19px;
      }
      &:nth-child(4n){
        margin-right: 0;
      }
      label {
        display: block;
        margin-top: 20px;
        font-size: 24px;
        font-weight: 400;
        color: #1D2129;
      }
      p {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #87909C;
        line-height: 1.2;
      }
    }
  }
  .core-box {
    overflow: hidden;
    .core-item {
      padding: 40px 28px 40px 30px;
      float: left;
      box-sizing: border-box;
      width: 590px;
      height: 159px;
      background: #FFFFFF;
      border-radius: 5px;
			margin-bottom: 20px;
      >img {
        width: 72px;
      }
      &:nth-child(2n-1) {
        margin-right: 20px;
      }
      img {
        float: left;
      }
      .text-box {
        margin-left: 102px;
        label {
          font-size: 24px;
          font-weight: 400;
          color: #1D2129;
        }
        p {
          margin-top: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #87909C;
          line-height: 1.2;
        }
      }
    }
  }
  .scenario-box {
    overflow: hidden;
    margin-bottom: 60px;
    .scenario-item {
      padding-top: 30px;
      float: left;
      width: 386px;
      background: #FFFFFF;
      border-radius: 5px;
      >img {
        width: 386px;
      }
      &:not(:last-child){
        margin-right: 19px;
      }
      label {
        font-size: 20px;
        font-weight: 400;
        color: #1D2129;
        padding: 30px 30px 0;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #87909C;
        margin-top: 22px;
        margin-bottom: 30px;
        padding: 0 30px;
        height: 77px;
        line-height: 1.2;
      }
    }
  }
}
</style>
